





































































































import { defineComponent } from "@vue/composition-api";
import { Button, Cell, Image, Picker, Popup, Search } from "vant";

export default defineComponent({
  components: {
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Search.name]: Search
  },
  data() {
    return {
      list: [] as { cabinetQrCode: string; cabinetMark: string; incomeAmount: number; refundAmount: number }[],
      listRaw: [] as { cabinetQrCode: string; cabinetMark: string; incomeAmount: number; refundAmount: number }[],
      shareId: (this.$route.query.shareId || 0) as number,
      total: 0,
      siteName: "",
      siteId: 0,
      startTime: 0,
      endTime: 0,
      timeMsg: "",
      search: "",
      searchRaw: "",
      showDeviceCategoryPicker: false,
      deviceCategorys: [
        { value: -1, label: "全部设备" },
        { value: 0, label: "充电设备" },
        { value: 1, label: "换电设备" }
      ],
      deviceCategory: { value: -1, label: "全部设备" },

      // -1 / 1  倒序 / 正序 / 无
      sortBy: 1, // 1 收益 2 退款
      sortState: -1
    };
  },
  created() {
    this.siteId = Number(this.$route.query.siteId) || 0;
    this.startTime = Number(this.$route.query.startTime) || 0;
    this.endTime = Number(this.$route.query.endTime) || 0;
    this.deviceCategory = this.deviceCategorys.find(v => v.value == Number(this.$route.query.deviceCategory) || 0) || this.deviceCategorys[0];
    this.timeMsg = decodeURIComponent(this.$route.query.timeMsg as string);
    this.siteName = decodeURIComponent(this.$route.query.siteName as string);
    this.getData();
  },
  methods: {
    getData() {
      const postData = {
        shareId: this.shareId,
        startTime: this.startTime,
        endTime: this.endTime,
        siteId: this.siteId,
        deviceCategory: this.deviceCategory && this.deviceCategory.value >= 0 ? this.deviceCategory.value : undefined
      };
      this.$axios.post("/api/manage/account/getAccountSiteStat", postData).then(res => {
        const data = res.data.data;
        this.total = data.total;
        this.list = data.list;
        this.listRaw = data.list;
        this.runSort();
      });
    },
    onClear() {
      this.searchRaw = "";
      this.onSearch();
    },
    onSearch() {
      this.search = this.searchRaw;
      this.runSort();
    },
    runSort() {
      this.list = this.listRaw
        .filter(x => !this.search || x.cabinetQrCode.includes(this.search) || x.cabinetMark.includes(this.search))
        .sort((v1, v2) => {
          if (this.sortBy == 1) {
            if (this.sortState == -1) {
              return v2.incomeAmount - v1.incomeAmount;
            } else {
              return v1.incomeAmount - v2.incomeAmount;
            }
          } else if (this.sortBy == 2) {
            if (this.sortState == -1) {
              return v2.refundAmount - v1.refundAmount;
            } else {
              return v1.refundAmount - v2.refundAmount;
            }
          }
          return 0;
        });
    },
    sortByIncome() {
      if (this.sortBy != 1) {
        this.sortBy = 1;
        this.sortState = -1;
      } else {
        this.sortState = 0 - this.sortState;
      }
      this.runSort();
    },
    sortByRefund() {
      if (this.sortBy != 2) {
        this.sortBy = 2;
        this.sortState = -1;
      } else {
        this.sortState = 0 - this.sortState;
      }
      this.runSort();
    },
    onPickDeviceCategory(deviceCategory: { value: number; label: string }) {
      this.deviceCategory = deviceCategory;
      this.showDeviceCategoryPicker = false;
      this.getData();
    }
  }
});
